// Modified from https://github.com/livekit-examples/meet/blob/main/lib/Debug.tsx

import * as React from 'react';
import { useRoomContext } from '@livekit/components-react';
import { setLogLevel, LogLevel, RemoteTrackPublication, setLogExtension, RemoteParticipant, LocalVideoTrack, VideoSenderStats, RemoteVideoTrack, RoomEvent, Participant, DataPacket_Kind } from 'livekit-client';
import { tinykeys } from 'tinykeys';
import * as DeviceMsg from './proto/messages/com/beeinventor/dasloopvideo/DeviceMsg';

import styles from '../styles/Debug.module.css';
import { SysTemp } from './proto/messages/com/beeinventor/dasloopvideo';

export const useDebugMode = ({ logLevel }: { logLevel?: LogLevel }) => {
  const room = useRoomContext();

  React.useEffect(() => {
    setLogLevel(logLevel ?? 'debug');

    // @ts-expect-error
    window.__lk_room = room;

    return () => {
      // @ts-expect-error
      window.__lk_room = undefined;
    };
  }, [room, logLevel]);
};

type RemoteStats = {
  fps: number;
}

function prettyPrintJson(jsonString: string): string {
  try {
    const json = JSON.parse(jsonString);
    return JSON.stringify(json, null, 2);
  } catch (e) {
    return jsonString;
  }
}

function DebugRemoteParticipant({ participant, deviceStats }: { participant: RemoteParticipant, deviceStats?: Map<string, number> }) {
  const p = participant;
  const [remoteStats, setRemoteStats] = React.useState(new Map<string, RemoteStats>());
  
  React.useEffect(() => {
    // timer to re-render
    const interval = setInterval(() => {
      const videoTracks = Array.from(p.trackPublications.values())
        .filter(t => t.videoTrack)
        .map(t => t.videoTrack as RemoteVideoTrack);
      const allStats = new Map<string, RemoteStats>();
      Promise.all(
        videoTracks
          .map(async remoteTrack => {
            const report = await remoteTrack.getRTCStatsReport();
            const stats: RemoteStats = {
              fps: null,
            };
            report.forEach((value, key) => {
              if (value.type === 'inbound-rtp') {
                stats.fps = value.framesPerSecond;
              }
            });
            allStats.set(remoteTrack.sid, stats);
            return stats;
          })
      ).then(() => {
        setRemoteStats(allStats);
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.detailsSection}>
      <summary>
        <b>
          {p.identity}
          <span></span>
        </b>
      </summary>
      <div>
        {Array.from(p.trackPublications.values()).map((t) => (
          <React.Fragment key={t.trackSid}>
            <div>
              <i>
                {t.source.toString()}
                &nbsp;<span>{t.trackSid}</span>
              </i>
            </div>
            <table>
              <tbody>
                <tr>
                  <td>{t.mimeType}</td>
                  <td>
                    {t.kind === 'video' && (
                      <span>
                        {t.dimensions?.width}x{t.dimensions?.height} {remoteStats.get(t.trackSid)?.fps ?? '?'} fps
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{trackStatus(t)}</td>
                </tr>
                {t.track && (
                  <tr>
                    <td>Bitrate</td>
                    <td>{Math.ceil(t.track.currentBitrate / 1000)} kbps</td>
                  </tr>
                )}
              </tbody>
            </table>
          </React.Fragment>
        ))}
        <div>
          <i>
            device stats
          </i>
        </div>
        <table>
          <tbody>
            {
              Array.from(deviceStats?.entries() ?? []).map(([key, value]) => (
                <tr>
                  <td>{key}</td>
                  <td>{value.toFixed(1)}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}

export const DebugMode = ({ logLevel, defaultOpened }: { logLevel?: LogLevel, defaultOpened?: boolean }) => {
  const room = useRoomContext();
  const [isOpen, setIsOpen] = React.useState(defaultOpened ?? false);
  const [, setRender] = React.useState({});
  const [roomSid, setRoomSid] = React.useState('');
  const [showLocalPermissions, setShowLocalPermissions] = React.useState(false);
  const [videoSenderStats, setVideoSenderStats] = React.useState(new Map<string, VideoSenderStats>());
  const [deviceStats, setDeviceStats] = React.useState(new Map<string, Map<string, number>>());
  const [roomMetadata, setRoomMetadata] = React.useState("");

  React.useEffect(() => {
    room.getSid().then(setRoomSid);

    room.on(RoomEvent.Connected, () => {
      console.log('room connected');
      console.log('room metadata', room.metadata);
      setRoomMetadata(prettyPrintJson(room.metadata));
    });
    room.on(RoomEvent.DataReceived, (payload: Uint8Array, participant: Participant, kind: DataPacket_Kind) => {
      try {
        const msg = DeviceMsg.decodeBinary(payload);
        console.log('Received message', msg);

        const stats = new Map(deviceStats.get(participant.sid));
        msg.payload.forEach((payload) => {
          const field = payload?.payload.field;
          if (field == "sysTemp") {
            const value:SysTemp = payload?.payload.value;
            stats.set('sys temp - ' + value.sensorName, value.reading);
          } else if (field) {
            console.warn(`unknown field ${field}`);
          }
        });
        setDeviceStats(new Map(deviceStats).set(participant.sid, stats));
      } catch (e) {
        console.error(`failed to decode message from ${participant.name}`, e);
        return;
      }
    });
    room.on(RoomEvent.RoomMetadataChanged, (metadata) => {
      console.log('Room metadata changed', metadata);
      setRoomMetadata(metadata);
    });
    return () => {
      room.removeAllListeners(RoomEvent.DataReceived);
      room.removeAllListeners(RoomEvent.RoomMetadataChanged);
    }
  }, [room]);

  useDebugMode({ logLevel });

  React.useEffect(() => {
    if (window) {
      const unsubscribe = tinykeys(window, {
        'Shift+D': () => {
          setIsOpen((open) => !open);
        },
      });

      // timer to re-render
      const interval = setInterval(() => {
        setRender({});

        const lp = room.localParticipant;
        Promise.all(
          Array.from(lp.videoTrackPublications.values())
            .filter(pub => pub.videoTrack)
            .map(async pub => {
              const lv = pub.videoTrack;
              return {
                sid: lv.sid,
                stats: await lv.getSenderStats()
              };
            })
        ).then(results => {
          const newStats = new Map<string, VideoSenderStats>();
          results.forEach(r => {
            if (r.stats.length != 1) {
              console.warn(`track ${r.sid} has ${r.stats.length} stats`, r.stats);
            }
            newStats.set(r.sid, r.stats[0]);
          });
          setVideoSenderStats(newStats);
        });
      }, 1000);

      return () => {
        unsubscribe();
        clearInterval(interval);
      };
    }
  }, [isOpen]);

  if (typeof window === 'undefined' || !isOpen) {
    return null;
  }

  if (!isOpen) {
    return <></>;
  }

  const lp = room.localParticipant;

  const localTracks = lp.trackPublications.size == 0 ? null : (
    <div className={styles.detailsSection}>
      <summary>
        <b>Published tracks</b>
      </summary>
      <div>
        {Array.from(lp.trackPublications.values()).map((t) => {
          const stats = videoSenderStats.get(t.trackSid);
          return (
            <>
              <div>
                <i>
                  {t.source.toString()}
                  &nbsp;<span>{t.trackSid}</span>
                </i>
              </div>
              <table>
                <tbody>
                  <tr>
                    <td>Kind</td>
                    <td>
                      {t.kind}&nbsp;
                      {t.kind === 'video' && (
                        <span>
                          {t.track?.dimensions?.width}x{t.track?.dimensions?.height} {stats?.framesPerSecond ?? '?'} fps
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Bitrate</td>
                    <td>
                      {Math.ceil(t.track!.currentBitrate / 1000)} kbps (target:{stats?.targetBitrate / 1000} kbps)
                    </td>
                  </tr>
                  <tr>
                    <td>Quality Limitation</td>
                    <td>
                      {t.kind === 'video' && (
                        <ul>
                          <li>reason: {stats?.qualityLimitationReason}</li>
                          <li>resolution changes: {stats?.qualityLimitationResolutionChanges}</li>
                          <li>duration: {JSON.stringify(stats?.qualityLimitationDurations)}</li>
                        </ul>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          );
        })}
      </div>
    </div>
  );

  const localPermissions = !showLocalPermissions ? null : (
    <div className={styles.detailsSection}>
      <summary>
        <b>Permissions</b>
      </summary>
      <div>
        <table>
          <tbody>
            {lp.permissions &&
              Object.entries(lp.permissions).map(([key, val]) => (
                <>
                  <tr>
                    <td>{key}</td>
                    {key !== 'canPublishSources' ? (
                      <td>{val.toString()}</td>
                    ) : (
                      <td> {val.join(', ')} </td>
                    )}
                  </tr>
                </>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className={styles.overlay}>
      <section id="room-info">
        <h3>
          Room {room.name}: {roomSid}
        </h3>
        <div>
          <summary>
            <b>Room Metadata</b>
          </summary>
          <pre style={{"fontSize": "smaller"}}>{prettyPrintJson(roomMetadata)}</pre>
        </div>
      </section>
      <div>
        <summary>
          <b>Local Participant: {lp.identity}</b>
        </summary>
        {localTracks}
        {localPermissions}
      </div>

      <div>
        <summary>
          <b>Remote Participants</b>
        </summary>
        {Array.from(room.remoteParticipants.values()).map(p => <DebugRemoteParticipant participant={p} key={p.sid} deviceStats={deviceStats.get(p.sid)} />)}
      </div>
    </div>
  );
};

function trackStatus(t: RemoteTrackPublication): string {
  if (t.isSubscribed) {
    return t.isEnabled ? 'enabled' : 'disabled';
  } else {
    return 'unsubscribed';
  }
}
