// @ts-nocheck
import {
  Type as DeviceMsgPayload,
  encodeJson as encodeJson_1,
  decodeJson as decodeJson_1,
  encodeBinary as encodeBinary_1,
  decodeBinary as decodeBinary_1,
} from "./DeviceMsgPayload";
import {
  Type as Timestamp,
  encodeJson as encodeJson_2,
  decodeJson as decodeJson_2,
  encodeBinary as encodeBinary_2,
  decodeBinary as decodeBinary_2,
} from "../../../google/protobuf/Timestamp";
import {
  jsonValueToTsValueFns,
} from "@pbkit/runtime/json/scalar";
import {
  WireMessage,
  WireType,
} from "@pbkit/runtime/wire/index";
import {
  default as serialize,
} from "@pbkit/runtime/wire/serialize";
import {
  default as deserialize,
} from "@pbkit/runtime/wire/deserialize";

export declare namespace $.com.beeinventor.dasloopvideo {
  export type DeviceMsg = {
    payload: DeviceMsgPayload[];
    timestamp?: Timestamp;
  }
}

export type Type = $.com.beeinventor.dasloopvideo.DeviceMsg;

export function getDefaultValue(): $.com.beeinventor.dasloopvideo.DeviceMsg {
  return {
    payload: [],
    timestamp: undefined,
  };
}

export function createValue(partialValue: Partial<$.com.beeinventor.dasloopvideo.DeviceMsg>): $.com.beeinventor.dasloopvideo.DeviceMsg {
  return {
    ...getDefaultValue(),
    ...partialValue,
  };
}

export function encodeJson(value: $.com.beeinventor.dasloopvideo.DeviceMsg): unknown {
  const result: any = {};
  result.payload = value.payload.map(value => encodeJson_1(value));
  if (value.timestamp !== undefined) result.timestamp = encodeJson_2(value.timestamp);
  return result;
}

export function decodeJson(value: any): $.com.beeinventor.dasloopvideo.DeviceMsg {
  const result = getDefaultValue();
  result.payload = value.payload?.map((value: any) => decodeJson_1(value)) ?? [];
  if (value.timestamp !== undefined) result.timestamp = decodeJson_2(value.timestamp);
  return result;
}

export function encodeBinary(value: $.com.beeinventor.dasloopvideo.DeviceMsg): Uint8Array {
  const result: WireMessage = [];
  for (const tsValue of value.payload) {
    result.push(
      [1, { type: WireType.LengthDelimited as const, value: encodeBinary_1(tsValue) }],
    );
  }
  if (value.timestamp !== undefined) {
    const tsValue = value.timestamp;
    result.push(
      [2, { type: WireType.LengthDelimited as const, value: encodeBinary_2(tsValue) }],
    );
  }
  return serialize(result);
}

export function decodeBinary(binary: Uint8Array): $.com.beeinventor.dasloopvideo.DeviceMsg {
  const result = getDefaultValue();
  const wireMessage = deserialize(binary);
  const wireFields = new Map(wireMessage);
  collection: {
    const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
    const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
    if (!value.length) break collection;
    result.payload = value as any;
  }
  field: {
    const wireValue = wireFields.get(2);
    if (wireValue === undefined) break field;
    const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
    if (value === undefined) break field;
    result.timestamp = value;
  }
  return result;
}
