// @ts-nocheck
import {
  Type as SysTemp,
  encodeJson as encodeJson_1,
  decodeJson as decodeJson_1,
  encodeBinary as encodeBinary_1,
  decodeBinary as decodeBinary_1,
} from "./SysTemp";
import {
  jsonValueToTsValueFns,
} from "@pbkit/runtime/json/scalar";
import {
  WireMessage,
  WireType,
  Field,
} from "@pbkit/runtime/wire/index";
import {
  default as serialize,
} from "@pbkit/runtime/wire/serialize";
import {
  default as deserialize,
} from "@pbkit/runtime/wire/deserialize";

export declare namespace $.com.beeinventor.dasloopvideo {
  export type DeviceMsgPayload = {
    payload?: (
      | { field: "sysTemp", value: SysTemp }
  );
  }
}

export type Type = $.com.beeinventor.dasloopvideo.DeviceMsgPayload;

export function getDefaultValue(): $.com.beeinventor.dasloopvideo.DeviceMsgPayload {
  return {
    payload: undefined,
  };
}

export function createValue(partialValue: Partial<$.com.beeinventor.dasloopvideo.DeviceMsgPayload>): $.com.beeinventor.dasloopvideo.DeviceMsgPayload {
  return {
    ...getDefaultValue(),
    ...partialValue,
  };
}

export function encodeJson(value: $.com.beeinventor.dasloopvideo.DeviceMsgPayload): unknown {
  const result: any = {};
  switch (value.payload?.field) {
    case "sysTemp": {
      result.sysTemp = encodeJson_1(value.payload.value);
      break;
    }
  }
  return result;
}

export function decodeJson(value: any): $.com.beeinventor.dasloopvideo.DeviceMsgPayload {
  const result = getDefaultValue();
  if (value.sysTemp !== undefined) result.payload = {field: "sysTemp", value: decodeJson_1(value.sysTemp)};
  return result;
}

export function encodeBinary(value: $.com.beeinventor.dasloopvideo.DeviceMsgPayload): Uint8Array {
  const result: WireMessage = [];
  switch (value.payload?.field) {
    case "sysTemp": {
      const tsValue = value.payload.value;
      result.push(
        [1, { type: WireType.LengthDelimited as const, value: encodeBinary_1(tsValue) }],
      );
      break;
    }
  }
  return serialize(result);
}

const oneofFieldNumbersMap: { [oneof: string]: Set<number> } = {
  payload: new Set([1]),
};

const oneofFieldNamesMap = {
  payload: new Map([
    [1, "sysTemp" as const],
  ]),
};

export function decodeBinary(binary: Uint8Array): $.com.beeinventor.dasloopvideo.DeviceMsgPayload {
  const result = getDefaultValue();
  const wireMessage = deserialize(binary);
  const wireFields = new Map(wireMessage);
  const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
  oneof: {
    const oneofFieldNumbers = oneofFieldNumbersMap.payload;
    const oneofFieldNames = oneofFieldNamesMap.payload;
    const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
    if (fieldNumber == null) break oneof;
    const wireValue = wireFields.get(fieldNumber);
    const wireValueToTsValueMap = {
      [1](wireValue: Field) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
    };
    const value = (wireValueToTsValueMap[fieldNumber as keyof typeof wireValueToTsValueMap] as any)?.(wireValue!);
    if (value === undefined) break oneof;
    result.payload = { field: oneofFieldNames.get(fieldNumber)!, value: value as any };
  }
  return result;
}
