// @ts-nocheck
import {
  tsValueToJsonValueFns,
  jsonValueToTsValueFns,
} from "@pbkit/runtime/json/scalar";
import {
  WireMessage,
} from "@pbkit/runtime/wire/index";
import {
  default as serialize,
} from "@pbkit/runtime/wire/serialize";
import {
  tsValueToWireValueFns,
  wireValueToTsValueFns,
} from "@pbkit/runtime/wire/scalar";
import {
  default as deserialize,
} from "@pbkit/runtime/wire/deserialize";

export declare namespace $.com.beeinventor.dasloopvideo {
  export type SysTemp = {
    reading: number;
    sensorName: string;
  }
}

export type Type = $.com.beeinventor.dasloopvideo.SysTemp;

export function getDefaultValue(): $.com.beeinventor.dasloopvideo.SysTemp {
  return {
    reading: 0,
    sensorName: "",
  };
}

export function createValue(partialValue: Partial<$.com.beeinventor.dasloopvideo.SysTemp>): $.com.beeinventor.dasloopvideo.SysTemp {
  return {
    ...getDefaultValue(),
    ...partialValue,
  };
}

export function encodeJson(value: $.com.beeinventor.dasloopvideo.SysTemp): unknown {
  const result: any = {};
  if (value.reading !== undefined) result.reading = tsValueToJsonValueFns.double(value.reading);
  if (value.sensorName !== undefined) result.sensorName = tsValueToJsonValueFns.string(value.sensorName);
  return result;
}

export function decodeJson(value: any): $.com.beeinventor.dasloopvideo.SysTemp {
  const result = getDefaultValue();
  if (value.reading !== undefined) result.reading = jsonValueToTsValueFns.double(value.reading);
  if (value.sensorName !== undefined) result.sensorName = jsonValueToTsValueFns.string(value.sensorName);
  return result;
}

export function encodeBinary(value: $.com.beeinventor.dasloopvideo.SysTemp): Uint8Array {
  const result: WireMessage = [];
  if (value.reading !== undefined) {
    const tsValue = value.reading;
    result.push(
      [1, tsValueToWireValueFns.double(tsValue)],
    );
  }
  if (value.sensorName !== undefined) {
    const tsValue = value.sensorName;
    result.push(
      [2, tsValueToWireValueFns.string(tsValue)],
    );
  }
  return serialize(result);
}

export function decodeBinary(binary: Uint8Array): $.com.beeinventor.dasloopvideo.SysTemp {
  const result = getDefaultValue();
  const wireMessage = deserialize(binary);
  const wireFields = new Map(wireMessage);
  field: {
    const wireValue = wireFields.get(1);
    if (wireValue === undefined) break field;
    const value = wireValueToTsValueFns.double(wireValue);
    if (value === undefined) break field;
    result.reading = value;
  }
  field: {
    const wireValue = wireFields.get(2);
    if (wireValue === undefined) break field;
    const value = wireValueToTsValueFns.string(wireValue);
    if (value === undefined) break field;
    result.sensorName = value;
  }
  return result;
}
